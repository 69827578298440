export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Cannon Clash",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://games.poki.com/458768/89395d4b-8e3a-488f-8f80-21d0acb6c0a0?tag=pg-8e61a433310ac6ecd976d92b7dd0ce3e7ebea2b5&site_id=73&iso_lang=zh&country=HK&poki_url=https://poki.com/zh/g/cannon-clash&hoist=yes&nonPersonalized=n&familyFriendly=n&categories=3,77,93,257,1018,1190&special_condition=landing",
    domain: "cannonclash.org",
    gaId: "G-07CH9QWVE4",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
